import React, { Component } from "react"
import { Link } from "gatsby"
import Slider from "react-slick"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types";

import "../css/carCarousel.css"
import "../css/globalRules.css"
import chevron from "../images/right.svg"

const carDisplayNames = {
  grand_i10_nios: "Grand i10 NIOS",
  grand_i10_nios_cng: "Grand i10 NIOS CNG",
  i20: "i20",
  // i20_iMT: "i20 iMT",
  i20_N_Line: "i20 N Line",
  // i20_N_Line_MT: "i20 N Line MT",
  aura: "Aura",
  aura_cng: "Aura CNG",
  all_new_verna: "All New Verna",
  creta: "Creta",
  creta_N_Line: "Creta N Line",
  venue: "Venue",
  venue_N_Line: "Venue N Line",
  // venue_iMT: "Venue iMT",
  tucson: "Tucson",
  // kona: "Kona",
  alcazar: "Alcazar",
  exter: "EXTER",
};

const SliderImages = (props) => {
  return (
    <StaticQuery query={graphql`
      query {

        aura: file(relativePath: { eq: "slider/aura.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        auraCng: file(relativePath: { eq: "slider/aura.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        nios: file(relativePath: { eq: "slider/nios.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        niosCng: file(relativePath: { eq: "slider/nios.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        i20: file(relativePath: { eq: "slider/i20.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        i20iMT: file(relativePath: { eq: "slider/i20.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        iMT: file(relativePath: { eq: "slider/I20-n-line-mt.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        i20NLine: file(relativePath: { eq: "slider/I20-n-line.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        kona: file(relativePath: { eq: "slider/kona.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        creta: file(relativePath: { eq: "slider/creta.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        cretaNLine: file(relativePath: { eq: "slider/creta-n-line.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        tucson: file(relativePath: { eq: "slider/tucson.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        allNewVerna: file(relativePath: { eq: "slider/new-verna-slider.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        alcazar: file(relativePath: { eq: "slider/alcazar.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        exter: file(relativePath: { eq: "slider/exter.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        venue: file(relativePath: { eq: "slider/venue.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        venueNLine: file(relativePath: { eq: "slider/venue-n-line.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        venueiMT: file(relativePath: { eq: "slider/venue-imt.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
    }`}

      render={data => {
        const imageObject = {
          "auraSlider": [{ ...data.aura.childImageSharp.fluid }],
          "auraCngSlider": [{ ...data.auraCng.childImageSharp.fluid }],
          "i20Slider": [{ ...data.i20.childImageSharp.fluid }],
          "konaSlider": [{ ...data.kona.childImageSharp.fluid }],
          "cretaSlider": [{ ...data.creta.childImageSharp.fluid }],
          "cretaNLineSlider": [{ ...data.cretaNLine.childImageSharp.fluid }],
          "niosSlider": [{ ...data.nios.childImageSharp.fluid }],
          "niosCngSlider": [{ ...data.niosCng.childImageSharp.fluid }],
          "tucsonSlider": [{ ...data.tucson.childImageSharp.fluid }],
          "allNewVernaSlider": [{ ...data.allNewVerna.childImageSharp.fluid }],
          "venueSlider": [{ ...data.venue.childImageSharp.fluid }],
          "alcazarSlider":[{ ...data.alcazar.childImageSharp.fluid }],
          "exterSlider":[{ ...data.exter.childImageSharp.fluid }],
          "iMTSlider":[{ ...data.i20iMT.childImageSharp.fluid }],
          "i20NLineSlider":[{ ...data.i20NLine.childImageSharp.fluid }],
          "i20NLineMTSlider":[{ ...data.iMT.childImageSharp.fluid }],
          "venueNLineSlider":[{ ...data.venueNLine.childImageSharp.fluid }],
          "venueiMTSlider":[{ ...data.venueiMT.childImageSharp.fluid }],

        }
        return (
          <Img fluid={imageObject[props.slideImage]}
            className={props.classNames} alt="Image" />
        )
      }}
    />

  )

}


SliderImages.propTypes = {
  slideImage: PropTypes.string.isRequired,
}


export default class CarCarousel extends Component {
  render() {
    var settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "5%",
      slidesToShow: 3,
      speed: 400,
      focusOnSelect: true,
      arrows: true,
      initialSlide: this.props.carIndex,
      ease: true,
      nextArrow: (
        <div>
          <div className="next-slick-arrow"> <img src={chevron} alt="nextArrow" />
          </div>
        </div>
      ),
      prevArrow: (
        <div>
          <div className="prev-slick-arrow"> <img src={chevron}
            alt="rightArrow" className="rotateimg180" /> </div>
        </div>
      ),

      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
          },
        },
      ],
    }

    return (
      <div>
        <h1 className="text-center mb-5">
          Models
        </h1>
        <div className="mb-5">
          {/* settings.initialSlide = {this.props.carIndex} */}
          <Slider {...settings}>
            <div>
              <Link to="/grand_i10_nios">
                {/* <img className="carousel-item img-fluid" src={nios} /> */}
                <SliderImages slideImage={"niosSlider"} classNames={"carousel - item"} />
              </Link>
              <br />
              <p className="text-center">{carDisplayNames["grand_i10_nios"]}</p>
            </div>
            <div>
                <Link to="/grand_i10_nios_cng">
                    {/* <img className="carousel-item img-fluid" src={nios} /> */}
                    <SliderImages slideImage={"niosCngSlider"} classNames={"carousel - item"} />
                  </Link>
                <br />
                <p className="text-center">{carDisplayNames["grand_i10_nios_cng"]}</p>
              </div>
            <div>
              <Link to="/i20">
                {/* <img className="carousel-item img-fluid" src={i20} /> */}
                <SliderImages slideImage={"i20Slider"} classNames={"carousel - item"} />
              </Link>
              <br />
              <p className="text-center">{carDisplayNames["i20"]}</p>
            </div>

            {/* <div>
              <Link to="/i20_iMT">
                // <img className="carousel-item img-fluid" src={i20} />
                <SliderImages slideImage={"iMTSlider"} classNames={"carousel - item"} />
              </Link>
              <br />
              <p className="text-center">{carDisplayNames["i20_iMT"]}</p>
            </div> */}

            <div>
              <Link to="/i20_N_Line">
                {/* <img className="carousel-item img-fluid" src={i20} /> */}
                <SliderImages slideImage={"i20NLineSlider"} classNames={"carousel - item"} />
              </Link>
              <br />
              <p className="text-center">{carDisplayNames["i20_N_Line"]}</p>
            </div>

            {/* <div>
              <Link to="/i20_N_Line_MT">
                // <img className="carousel-item img-fluid" src={i20} />
                <SliderImages slideImage={"i20NLineMTSlider"} classNames={"carousel - item"} />
              </Link>
              <br />
              <p className="text-center">{carDisplayNames["i20_N_Line_MT"]}</p>
            </div> */}

            <div>
              <Link to="/aura">
                <SliderImages slideImage={"auraSlider"} classNames={"carousel - item"} />
              </Link>
              <br />
              <p className="text-center">{carDisplayNames["aura"]}</p>
            </div>
            <div>
              <Link to="/aura_cng">
                  <SliderImages slideImage={"auraCngSlider"} classNames={"carousel - item"} />
                </Link>
              <br />
              <p className="text-center">{carDisplayNames["aura_cng"]}</p>
            </div>

            <div>
              <Link to="/all_new_verna">
                <SliderImages slideImage={"allNewVernaSlider"} classNames={"carousel - item"} />
              </Link>
              <br />
              <p className="text-center">{carDisplayNames["all_new_verna"]}</p>
            </div>

            <div>
              <Link to="/creta">
                <SliderImages slideImage={"cretaSlider"} classNames={"carousel - item"} />
              </Link>
              <br />
              <p className="text-center">{carDisplayNames["creta"]}</p>
            </div>

            <div>
              <Link to="/creta_N_Line">
                <SliderImages slideImage={"cretaNLineSlider"} classNames={"carousel - item"} />
              </Link>
              <br />
              <p className="text-center">{carDisplayNames["creta_N_Line"]}</p>
            </div>

            <div>
              <Link to="/venue">
                <SliderImages slideImage={"venueSlider"} classNames={"carousel - item"} />
              </Link>
              <br />
              <p className="text-center">{carDisplayNames["venue"]}</p>
            </div>
            <div>
              <Link to="/venue_N_Line">
                {/* <img className="carousel-item img-fluid" src={i20} /> */}
                <SliderImages slideImage={"venueNLineSlider"} classNames={"carousel - item"} />
              </Link>
              <br />
              <p className="text-center">{carDisplayNames["venue_N_Line"]}</p>
            </div>
            {/* <div>
              <Link to="/venue_iMT">
                // <img className="carousel-item img-fluid" src={i20} />
                <SliderImages slideImage={"venueiMTSlider"} classNames={"carousel - item"} />
              </Link>
              <br />
              <p className="text-center">{carDisplayNames["venue_iMT"]}</p>
            </div> */}
            <div>
              <Link to="/tucson">
                <SliderImages slideImage={"tucsonSlider"} classNames={"carousel - item"} />
              </Link>
              <br />
              <p className="text-center">{carDisplayNames["tucson"]}</p>
            </div>

            {/* <div>
              <Link to="/kona">
                <SliderImages slideImage={"konaSlider"} classNames={"carousel - item"} />
              </Link>
              <br />
              <p className="text-center">{carDisplayNames["kona"]}</p>
            </div> */}

            <div>
              <Link to="/alcazar">
                <SliderImages slideImage={"alcazarSlider"} classNames={"carousel - item"} />
              </Link>
              <br />
              <p className="text-center">{carDisplayNames["alcazar"]}</p>
            </div>
            <div>
              <Link to="/exter">
                <SliderImages slideImage={"exterSlider"} classNames={"carousel - item"} />
              </Link>
              <br />
              <p className="text-center">{carDisplayNames["exter"]}</p>
            </div>
          </Slider>
        </div>
      </div>
    )
  }
}
